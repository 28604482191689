import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { countUserEvent } from "../utils/utlis";

AOS.init();
export default function Index() {

  const googleProvider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider('apple.com')
  const microsoftProvider = new OAuthProvider('microsoft.com');
  const auth = getAuth();
  const navigate = useNavigate();

  const signIn = (provider) => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        const user = result.user
        console.log("Google Signup", user)
        // console.log("Google Signup", token)
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        // const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("Google Signup", error)
        console.log("Google Signup", errorCode)
        console.log("Google Signup", errorMessage)
        console.log("Google Signup", email)
        // console.log("Google Signup", credential)

      });
  }
  useEffect(() => {
    if (auth.currentUser) {
      navigate('/organizations');
    }
  }, [navigate, auth.currentUser])
  // organizations
  return (
    <>
      <div className='accounts-body'>
        <img className='accounts-logo' src={require('../images/logo/logo.png')} alt="logo" />
        <div className='create-account-card'>
          <h1>Create an ilmiya account</h1>
          <Link to="/signup/email" className='email-signup-btn'>
            <i className="fa-regular fa-envelope-open"></i>
            <span>
              Sign up with email
            </span>
          </Link>
          <div className='devider'>
            <div className='linee'></div>
            <span>Or continue with</span>
          </div>
          <div className='other-options'>
            <div className='row'>
              <div className='col-4'>
                <div className='option-btn' onClick={() => signIn(googleProvider)}>
                  <img src={require('../images/logo/Google.png')} />
                </div>
              </div>
              <div className='col-4'>
                <div className='option-btn' onClick={() => signIn(appleProvider)}>
                  <img src={require('../images/logo/apple.png')} />
                </div>
              </div>
              <div className='col-4'>
                <div className='option-btn' onClick={() => signIn(microsoftProvider)}>
                  <img src={require('../images/logo/Microsoft.png')} />
                </div>
              </div>
            </div>
          </div>
          <div  onClick={()=>{ countUserEvent('Already_Signed_In' , {eventName : "Already_Signed_In"} ) }} className='already-have-account'>
            Already have an ilmiya account? <Link to="/signin"> Login <i className="fa-regular fa-arrow-right"></i></Link>
          </div>
          <div  onClick={()=>{ countUserEvent('Agree_Terms_Services' , {eventName : "Agree_Terms_Services"} ) }} className='terms-privacy'>
            By creating an account you agree to the <a href='https://ilmiya.com/legal/terms-of-service'>Terms of Service</a> and <a href='https://ilmiya.com/legal/privacy-policy'>Privacy Policy</a>.
          </div>
          <div className='help-center'>
            <a href='https://docs.ilmiya.com'>Help</a>
            <span>|</span>
            <a href='https://ilmiya.com/legal/privacy-policy'>Privacy</a>
            <span>|</span>
            <a href='https://ilmiya.com/legal/terms-of-service'>Terms</a>
          </div>
        </div>
      </div>
    </>
  );
}
