
import Countly from '../config/countly';

export const countUserEvent = (eventName, eventData = {}) => {
  Countly.add_event({
    key: eventName,
    count: 1,
    segmentation: eventData
  });
};
